import Bateria from '../images/foto_bateria_palheta.png';
import Guitarra from '../images/foto_guitarra_palheta.png';
import Microfone from '../images/foto-microfone.png';
import Baixo from '../images/foto-baixo.png';
import Sopro from '../images/foto-sopro.png';
import Teclado from '../images/foto-teclado.png';
import FundoImagem from '../images/Fundo_Palheta.png';
import FundoImagem1 from '../images/Fundo_Palheta_1.png';
import FundoImagem2 from '../images/Fundo_Palheta_2.png';
import FundoImagem3 from '../images/Fundo_Palheta_3.png';

export const courseData = [
    {
        name: "Luan",
        fullname: "Luan Medeiros",
        instrument: "Bateria",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia provident esse laboriosam blanditiis iusto porro nesciunt aliquid libero distinctio, obcaecati minus sequi error, molestias necessitatibus harum nobis non. Amet, blanditiis? Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia provident esse laboriosam blanditiis iusto porro nesciunt aliquid libeis?",
        image: Bateria,
        fundo: FundoImagem
    },
    {
        name: "William",
        fullname: "William",
        instrument: "Guitarra - Violão",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia provident esse laboriosam blanditiis iusto porro nesciunt aliquid libero distinctio, obcaecati minus sequi error, molestias necessitatibus harum nobis non. Amet, blanditiis? Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia provident esse laboriosam blanditiis iusto porro nesciunt aliquid libero distinctio, obcaecati minus sequi error, molestias necessitatibus harum nobis non. Amet, blanditiis?",
        image: Guitarra,
        fundo: FundoImagem1
    },
    {
        name: "Japa",
        fullname: "Japa",
        instrument: "Baixo",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia provident esse laboriosam blanditiis iusto porro nesciunt aliquid libero distinctio, obcaecati minus sequi error, molestias necessitatibus harum nobis non. Amet, blanditiis? Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia provident esse laboriosam blanditiis iusto porro nesciunt aliquid libero distinctio, obcaecati minus sequi error, molestias necessitatibus harum nobis non. Amet, blanditiis?",
        image: Baixo,
        fundo: FundoImagem2,
    },
    {
        name: "Professor",
        fullname: "Professor Canto ",
        instrument: "Canto",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia provident esse laboriosam blanditiis iusto porro nesciunt aliquid libero distinctio, obcaecati minus sequi error, molestias necessitatibus harum nobis non. Amet, blanditiis? Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia provident esse laboriosam blanditiis iusto porro nesciunt aliquid libero distinctio, obcaecati minus sequi error, molestias necessitatibus harum nobis non. Amet, blanditiis?",
        image: Microfone,
        fundo: FundoImagem1
    },
    {
        name: "Professor",
        fullname: "Professor Sopro",
        instrument: "Sopro",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia provident esse laboriosam blanditiis iusto porro nesciunt aliquid libero distinctio, obcaecati minus sequi error, molestias necessitatibus harum nobis non. Amet, blanditiis? Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia provident esse laboriosam blanditiis iusto porro nesciunt aliquid libero distinctio, obcaecati minus sequi error, molestias necessitatibus harum nobis non. Amet, blanditiis?",
        image: Sopro,
        fundo: FundoImagem3
    },
    {
        name: "Professor",
        fullname: "Professor Teclado",
        instrument: "Teclado",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia provident esse laboriosam blanditiis iusto porro nesciunt aliquid libero distinctio, obcaecati minus sequi error, molestias necessitatibus harum nobis non. Amet, blanditiis? Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia provident esse laboriosam blanditiis iusto porro nesciunt aliquid libero distinctio, obcaecati minus sequi error, molestias necessitatibus harum nobis non. Amet, blanditiis?",
        image: Teclado,
        fundo: FundoImagem
    },
    
]